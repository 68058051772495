'use client';

import ErrorPage from '@/components/error/error-page';

export const metadata = {
  title: 'Page Not Found',
};

export default function PageNotFound() {
  return (
    <ErrorPage
      title="What the Health?"
      subtitle={`Oops, you’ve hit a wall. This page is either under construction, or doesn't exist. Apologies for the inconvenience! Head home to learn more about our mission to free 2 billion from pain.`}
      buttonLabel="Return Home"
      buttonProps={{
        href: '/',
      }}
    />
  );
}
